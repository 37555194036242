import { SlicesUIElement, html } from '@slices/ui/src/element/element.js';
import { enableFocusVisible } from '@slices/ui/src/utils/focus-visible-polyfill.js';
import { StringConverter } from 'html-element-property-mixins/src/utils/attribute-converters';
import '@slices/ui/src/image/image.js';
import { SlicesStory } from '@slices/player/src/models/SlicesStory.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';

class EnergieCard extends SlicesUIElement {

  static get properties() {
    return {
      storyId: {
        observe: true,
        DOM: true,
        changedHandler: '_storyIdChanged',
        attributeName: 'story-id',
        fromAttributeConverter: StringConverter.fromAttribute
      },
      title: {
        observe: true,        
      },
      subtitle: {
        observe: true,        
      },
      image: {
        observe: true,        
      }
    };
  }

  static get propertiesChangedHandlers() {
    return {
      render: ['title', 'subtitle', 'image']
    };
  }

  constructor() {
    super();
    this.title = '';
    this.subtitle = '';
    this.image = {};
    this.addEventListener('keypress', e => {
      if(e.which === 13) this.dispatchEvent(new Event('click', {composed: true, bubbles: true}));
    });
  }

  connectedCallback() {
    super.connectedCallback();
    if(!this.hasAttribute('tabindex')) this.setAttribute('tabindex', 0);
    enableFocusVisible(this);
  }

  get template() {
    return html`
      <style>
        :host {
          display: block;
          padding: var(--erem) calc(var(--erem) / 2);
        }

        :host([unpublished]) {
          pointer-events: none;
          filter: grayscale(100%);
        }

        :host(:not(.focus-visible)) {
          outline: none;
        }

        * {
          box-sizing: border-box;
        }

        section {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #222;
          width: 100%;
          min-height: 100%;
          text-align: center;
          white-space: pre-wrap;
          position: relative;
          padding: calc(var(--erem)*1.5);
          overflow: hidden;
          transition: 200ms transform ease;
        }

        section:hover {
          transform: translate(10px, 10px);
        }

        section:hover slicesui-image {
          transform: scale(1);
        }

        h1 {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          margin: 0;
          font-family: var(--slices-text-h1--font-family, var(--slices-text-h--font-family, var(--slices-text--font-family)));
          font-size: var(--slices-text-h1--font-size);
          font-weight: 700;
          line-height: var(--slices-text-h1--line-height, var(--slices-text-h--line-height));
          color: white;
          position: relative;
          z-index: 1;
          width: 100%;
        }

        h3 {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          margin: 0;
          font-family: var(--slices-text-b1--font-family, var(--slices-text-b--font-family, var(--slices-text--font-family)));
          font-size: var(--slices-text-s1--font-size);
          font-weight: var(--slices-text-s1--font-weight, var(--slices-text-b--font-weight, 400));
          line-height: var(--slices-text-s1--line-height, var(--slices-text-b--line-height));
          color: white;
          position: relative;
          z-index: 2;
          margin-top: var(--erem);
          width: 100%;
        }

        slicesui-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          --slicesui-image-object-fit: cover;
          transform: scale(1.05);
          transition: 200ms filter ease, 200ms transform ease;
        }

        svg {
          position: absolute;
          width: calc(var(--erem)*2);
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }

        svg:first-of-type {
          top: calc(var(--erem)*1.5);
        }

        svg:last-of-type {
          bottom: calc(var(--erem)*1.5);
        }

      </style>
      <section>
      <h1>${this.title}</h1>
      <h3>${this._parseHTML(this.subtitle)}</h3>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 4"><path fill="#fff" fill-rule="evenodd" d="M0 0v4c20.136-.68 41.788-.68 62 0V0C41.788.604 20.136.604 0 0"/></svg>

      <slicesui-image
        .placeholder="${this.image.previewUrl}"
        .sources="${this.image.sizes}"
        preload
      ></slicesui-image>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 4"><path fill="#fff" fill-rule="evenodd" d="M0 0v4c20.136-.68 41.788-.68 62 0V0C41.788.604 20.136.604 0 0"/></svg>

    </section>
    `;
  }

  async _storyIdChanged(oldVal, storyId) {
    if(!storyId) return;
    const res = await fetch(`https://another-proto.firebaseio.com/versions.json?orderBy="storyId"&equalTo="${this.storyId}"&limitToLast=1`);
    const json = await res.json();
    const versionId = Object.keys(json)[0];
    const data = json[versionId];
    const story = new SlicesStory(data.storyData);
    this.title = story.title;
    this.subtitle = story.introText;
    this.image = story.coverImage;
  }

  _parseHTML(html) {
    const span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent;
  }
 
}

window.customElements.define('energie-card', EnergieCard);